.mui-editor-height {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.mui-editor-height .MuiTiptap-RichTextField-content {
  flex: 1;
}

.mui-editor-height .MuiTiptap-RichTextField-content > div {
  min-height: 100%;
}

div[role="tooltip"] {
  z-index: 9999 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: 9999
}

